.gcl {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gcl.background {
  position: absolute;
  height: 290px;

  z-index: 10;

  background-position-y: 65% !important;
}

.gcl.content {
  position: absolute;
  z-index: 100;
}

.button,
.button:active,
.button:focus {
  background: #d505ff;
}

.button:hover {
  background: #fc05cf;
}
