::placeholder {
  color: #343434;
  opacity: 1;
}

.checklist .sticky.is-stuck .listmenu {
  margin: 5px 5px 0;

  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
}

.dropdown-pane {
  width: 200px;
}

.checklist input[type="checkbox"] {
  margin: 0 10px 0 0;
}

.checklist {
  color: #343434 !important;
}

.checklist .listmenu {
  height: 60px;
}

.checklist .datapanel {
  color: #fff;

  background: #5f5f5f;
  border: 1px solid #444242;

  padding: 8px;
  margin-bottom: 10px;

  transition: all 0.4s;
}

.checklist #list-belowmenubar .datapanel label {
  color: #fff;
}

.checklist .datapanel .input-group {
  margin-bottom: 0;
}

.checklist .datapanel .button {
  margin: 0;
}

.checklist .datapanel .button.small,
.checklist .datapanel .button.small i {
  font-size: 0.85rem;
}

.checklist .datapanel p {
  margin: 5px 0;
}

.checklist .item .hero {
  margin: 50px 0;
  color: #f0f0f0;
}

.checklist .item .hero .columns div {
  padding: 50px 0;
  background-size: cover !important;
  background-position: center center !important;

  text-align: center;

  transition: all 0.4s;
}

.checklist .item .hero .columns div:hover,
.checklist .item .hero .columns div:focus {
  transform: scale(0.94);
}

.checklist .item .head {
  background: #dadada;
  padding: 10px;

  transition: all 0.4s ease-in-out;
}

.checklist .item .head.overhere {
  transform: scale(1.1);
}

.checklist .item.active .head {
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);

  position: relative;
}

.checklist .item:not(:last-of-type) .head {
  border-bottom: 1px solid #b3b3b3;
}

.checklist .titlerow p {
  margin: 0;
}

.checklist .title p {
  margin: 9px 0 10px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checklist .title p a {
  color: #343434;
}

.checklist .item .info {
  background: #464646;
  color: #dadada;
  padding: 25px;
}

.checklist .item .info .timeline {
  padding: 25px 0 0;
}

.checktainer {
  display: block;
  position: relative;
  padding: 10px;
  margin: 9px 0 12px 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checktainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #585858;

  -webkit-box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.2);

  transition: all 0.4s;
}

.checktainer:hover input ~ .checkmark {
  background-color: #464646;
}

.checktainer input:checked ~ .checkmark {
  background: #d505ff;
  background: -moz-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: linear-gradient(135deg, #d505ff 9%, #fc05cf 49%, #ff3019 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#d505ff",
      endColorstr="#ff3019",
      GradientType=1
    );
  -webkit-box-shadow: none;
  box-shadow: none;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checktainer input:checked ~ .checkmark:after {
  display: block;
}
.checktainer .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.carat {
  margin: 17px 0 0 0;
  width: 0;
  height: 0;
  transition: all 0.4s;
}
.carat.down {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #343434 !important;
  border-bottom: none !important;
}
.carat.up {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #343434;
  border-top: none;
}
