html,
body,
#root {
  height: 100%;
  background: #1e1e1e;

  color: #fff;
}

@keyframes slideDown {
  0% {
    transform: translateY(-230%);
  }
  100% {
    transform: translateY(0);
  }
}

.choose {
  max-height: 100vh;
}

.choose > div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: black;

  transition: all 0.4s;
}

.choose svg {
  width: 50%;
  margin: 0 auto;
}

.one > div {
  background-image: url("../../public/assets/img/game-bg-1.jpg");
}
.two > div {
  background-image: url("../../public/assets/img/game-bg-2.jpg");
}
.three > div {
  background-image: url("../../public/assets/img/game-bg-3.jpg");
}

.choose:hover > div,
.choose:focus > div {
  transform: scale(0.94);
}

.help {
  position: absolute;
  z-index: 9999;

  width: 350px;
  height: 50px;

  top: 25px;
  left: calc(50% - (350px / 2));

  margin: 0 auto;

  border-radius: 50px;

  color: #fff;

  font-size: 13px;

  background: #d505ff;
  background: -moz-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: linear-gradient(135deg, #d505ff 9%, #fc05cf 49%, #ff3019 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#d505ff",
      endColorstr="#ff3019",
      GradientType=1
    );

  -webkit-box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);
  box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);

  animation: 0.5s ease-in 0s 1 slideDown;
  transition: all 0.4s;
}

.help span {
  display: inline-block;
  padding: 16px 10px 14px 20px;
}

.help .link {
  padding: 16px 20px 14px 20px;
  color: #fff;

  border-left: 1px solid #d32512;

  transition: all 0.4s;
}

.help .link:hover {
  background: #d505ff;
  border-radius: 0 50px 50px 0;
}

.help .close {
  position: relative;
  z-index: 999;

  width: 25px;
  height: 25px;

  background: #fc05cf;
  color: #fff;
  text-align: center;
  font-size: 20px;
  line-height: 24px;

  border-radius: calc(25px / 2);

  -webkit-box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);
  box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);

  transition: all 0.4s;
}

.help .close:hover {
  background: #ff3019;
}

.help-hidden {
  position: absolute;
  z-index: 9999;

  width: 55px;
  height: 55px;

  top: calc(-55px / 2);
  right: 15vw;

  border-radius: calc(55px / 2);

  font-size: 32px;
  line-height: 70px;
  text-align: center;

  color: #fff;

  background: #d505ff;

  -webkit-box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);
  box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);

  animation: 0.5s ease-out 0s 1 slideDown;
  transition: all 0.4s;
}

.help-hidden:hover {
  background: #d32512;
  color: #fff;
}

/* help sizing */
@media screen and (min-width: 40em) {
  /* medium+ */
  .help .close {
    top: -15px;
    left: 105px;
  }
}

@media screen and (max-width: 39.9375em) {
  /* small- */
  .help {
    top: 15px;
  }

  .help .close {
    top: 55px;
    left: 50px;

    width: 35px;
    height: 35px;

    line-height: 33px;

    border-radius: calc(35px / 2);
  }
}

@media screen and (max-width: 374.9px) {
  /* tiny- */
  .help {
    top: 0;
    left: 0;
    right: 0;

    width: 100%;

    position: unset;
  }

  .help .close {
    position: absolute;

    top: 60px;
    right: 20px;
    left: unset;

    width: 35px;
    height: 35px;

    line-height: 33px;

    border-radius: calc(35px / 2);
  }

  .help-mob-comp {
    height: calc(100vh - 50px) !important;
  }
}
