.tl {
  position: fixed;
  z-index: 99;
  top: 0;

  margin-left: -100px;

  transition: top .2s ease-out;
}

.tl ul {
  list-style: none;
  list-style-position: inside;

  margin: 15px 0;
}

.tl ul li {
  margin: 15px 0;
  padding-left: 0;

  list-style-type: none;

  color: #f0f0f0;
  
  cursor: pointer;

  transition: all 0.3s ease-out;
}

.tl ul li b {
  font-weight: normal;
} .tl ul li.active b {
  font-weight: 700;
}

.tl ul li.active {
  color: #d505ff !important;
  
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.tl ul li:hover {
  color: #fc05cf;
}
