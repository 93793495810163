.loading-overlay {
  position: fixed;

  width: 40px;
  height: 40px;

  bottom: 20px;
  right: 20px;

  border-radius: 50%;

  background: #d505ff;
  background: -moz-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #d505ff 9%,
    #fc05cf 49%,
    #ff3019 100%
  );
  background: linear-gradient(135deg, #d505ff 9%, #fc05cf 49%, #ff3019 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#d505ff",
      endColorstr="#ff3019",
      GradientType=1
    );
  -webkit-box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);
  box-shadow: 0 0 15px 2px rgba(30, 30, 30, 0.4);
}

.loading-overlay .loader {
  width: 30px;
  height: 30px;
  margin: 5px;
  fill: #fff;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);

    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-overlay .loader {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}